@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#Photos {
  background-color: #222222;
  padding-top: 84px
}

#Photos::before {
    content: '';
    display: block;
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
  }

#Photos::after {
    content: '';
    clear: both;
    display: block;
  }

#Photos aside {
    float: left;
    width: 300px;
    position: fixed;
    padding-top: 100px;
    top: 0;
    bottom: 0;
    box-shadow: 0 1px 1px rgba(255, 255, 255, .5);
  }

#Photos aside img.camera {
        width: 70%;
        margin: 60px 0 30px 15%;
      }

#Photos aside ul {
      padding: 0 15px;
    }

#Photos aside ul li {
        position: relative;
      }

#Photos aside ul li i {
          font-size: 23px;
          opacity: 0;
          position: absolute;
          right: 10px;
          top: calc(50% - 12.5px);
          transition: all .3s ease-in-out;
        }

#Photos aside ul li a {
          background-color: rgb(78, 78, 78);
          color:  #111;
          cursor: pointer;
          display: block;
          font-weight: bold;
          height: 45px;
          left: 0;
          line-height: 45px;
          margin-bottom: 5px;
          padding: 0 15px;
          position: relative;
          transition: all .1s ease-in-out;
          width: 100%;
        }

#Photos aside ul li.active a, #Photos aside ul li:hover a {
            left: 30px;
            transition: all .1s ease-in-out;
          }

#Photos aside ul li.active i, #Photos aside ul li:hover i {
            opacity: 1;
            right: -30px;
          }

#Photos article {
    width: calc(100% - 300px - 20px);
    float: right;
    padding-top: 30px;
    padding-right: 20px;
    position: relative
  }

#Photos article.panorama .image-gallery-content .image-gallery-slide img {
            width: 100%;
            height: auto;
          }

#Photos .no_album_found {
    width: 100%;
    line-height: 300px;
    font-size: 40px;
    position: relative;
    text-align: center;
  }

#Photos .image-gallery,
  #Photos .image-gallery-content.fullscreen,
  #Photos .image-gallery-slide {
    background-color: #222;
  }

#Photos .image-gallery-slide.center{
    z-index: 3;
  }

#Photos .image-gallery-content.fullscreen .image-gallery-slide img {
          height: 100vh;
        }

#Photos .image-gallery-content .image-gallery-slide img {
        width: auto;
        height: calc(100vh - 222px);
      }

#Photos .image-gallery-content .image-gallery-slide.center > div {
        text-align: center;
      }

#Photos .image-gallery-fullscreen-button {
    bottom: auto;
    top: 30px
  }

#Photos .image-gallery-fullscreen-button.active {
    top: 0;
    }

#Photos .img_zoom_container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    text-align: center;
    background-color: #222222;
  }

@media (max-width: 980px) {
    #Photos aside {
      width: 160px;
      height: auto;
      position: relative;
      padding: 20px 0 20px;
    }

      #Photos aside img.camera {
        margin-top: 0;
        margin-bottom: 20px;
      }

        #Photos aside ul li a {
          font-size: 13px;
        }

          #Photos aside ul li.active a, #Photos aside ul li:hover a {
            left: 20px;
            transition: all .1s ease-in-out;
          }

          #Photos aside ul li.active i, #Photos aside ul li:hover i {
            opacity: 1;
            right: -15px;
          }

    #Photos article {
      width: calc(100% - 160px - 10px);
    }
  }

@media (max-width: 500px) {

#Photos {
    padding-top: 66px;
}

    #Photos aside {
      padding: 0 0 5px;
    }
  }

@media (max-width: 560px) {
    #Photos aside{
      width: 100%;
      position: relative;
    }

      #Photos aside img.camera {
        display: inline-block;
        width: 40px;
        margin: 0 0 0 20px;
        vertical-align: top;
      }

      #Photos aside ul {
        width: calc(100% - 60px);
        display: inline-block;
      }
        #Photos aside ul li {
          opacity: 0;
          z-index: -1;
          height: 0;
          overflow: hidden
        }

          #Photos aside ul li:first-child {
            opacity: 1;
            z-index: 1;
            height: auto;
          }

          #Photos aside ul li a {
            height: 34px;
            line-height: 34px;

          }

            #Photos aside ul li a span {
              font-style: italic;
              font-weight: bold;
              font-size: 12px;
              display: inline-block;
              margin-right: 10px;
            }
            #Photos aside ul li.active a, #Photos aside ul li:hover a {
              left: 0;
            }

            #Photos aside ul li.active i, #Photos aside ul li:hover i {
              right: 15px
            }

              #Photos aside ul li.active i.fa-chevron-right, #Photos aside ul li:hover i.fa-chevron-right {
                transform: rotate(90deg)
              }
          #Photos aside.active ul li {
            opacity: 1;
            z-index: 1;
            height: auto;
          }

    #Photos article{
      width: 100%;
      padding-left: 20px;
    }
      #Photos .image-gallery-content .image-gallery-slide img {
        width: 100%;
        height: auto;
      }
          #Photos .image-gallery-content.fullscreen .image-gallery-slide img {
            height: auto;
          }
  }

:root {
  --color-primary: #f6eb24;
  --color-secondary: #cacaca;
  --color-gray-light: #e9e9e9;
  --color-gray-dark: #666666;
  --color-bg-dark: #333333;
  --color-bg-dark2: #414344;
  --color-light: rgba(255, 255, 255, 1);
  --color-alert: rgb(238, 44, 44);
  --color-success: rgb(74, 160, 78);
  --color-warning: rgb(255, 234, 116);
  --color-hover-black-light: rgba(0, 0, 0, .2);
  --color-hover-black-dark: rgba(0, 0, 0, .8);
  --color-hover-light: rgba(255, 255, 255, .2);
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, address, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small, strong, sub,
sup, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

*, *:before, *:after {
  box-sizing: border-box;
  transition: all .1s linear;
}

::scrollbar { display: none; }

html {
  height: 100%; /* end body */
}

html body {
    color: var(--color-bg-dark);
    background-color: #7b7b7b;
    line-height: 1;
    text-size-adjust: 100%;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
  }

/* end html */

#root {
  height: 100%;
}

.full-height {
  height: 100%;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table th {
    font-weight:normal;
  }

label, button, input[type="submit"] {
  cursor:pointer;
}

input.button {
  width: auto;
}

a, a:visited, a:active, a:focus {
  text-decoration: none;
  color:var(--color-secondary);
  outline:none;
}

a:hover {
    text-decoration: none;
    color:#000;
  }

input, textarea, select {
  width: 100%;
  outline: none;
  height: 40px;
  padding: 0 10px;
}

input::-webkit-input-placeholder {
    color:var(--color-gray-light);
  }

input:-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

input::-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

input:-ms-input-placeholder {
    color:var(--color-gray-light);
  }

input[type='radio'],
  input[type='checkbox'] {
    width: auto;
    vertical-align: middle;
    margin: 0 3px 0 10px;
  }

input:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

textarea {
  height:100px;
  margin-bottom: 20px;
  width:100%
}

textarea::-webkit-input-placeholder {
    color:var(--color-gray-light);
  }

textarea:-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

textarea::-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

textarea:-ms-input-placeholder {
    color:var(--color-gray-light);
  }

select{
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  text-indent: 0.01px;
  text-overflow: ''
}

select::placeholder {
    color:var(--color-gray-light);
  }

select::-ms-expand {
    display: none;
  }

select:focus {
    outline: none;
  }

select:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

select option {
    padding: 2px;
  }

span.clear {
    display:block;
    clear:both;
    height: 0;
  }

span.space{
    display:block;
    margin:10px 0;
    height:1px; clear:both;
  }

.transition, .transition:hover {
  transition:all 0.3s ease-in ;
}

::selection {
  background: var(--color-secondary);
  color: #eee;
}

.clear:after{
  content:'';
  display:block;
  clear:both;
}

.pointer {
  cursor: pointer;
}

.align-left {
  text-align:left;
}

.align-right {
  text-align:right;
}

.align-center {
  text-align:center;
}

.align-justify {
  text-align:justify;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.center{
  display:block !important;
  margin-left:auto !important;
  margin-right:auto !important;
}

.margin-top {
  margin-top:2rem;
}

.comum-color{
  color: var(--color-gray-dark);
  text-shadow: 0 0 1px rgba(0,0,0, 0.2);
}

.relative{
  position:relative;
}

.button {
  background: var(--color-primary);
  border: none;
  color: var(--color-light);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  display: block;
  margin: 1rem 0;
  padding: 10px 20px;
}

.button .full-width {
    width: 100%;
  }

.button.cancel {
    background: var(--color-gray-dark);
    color: var(--color-bg-dark);
  }

.button i {
    margin: 0 5px;
  }

.format-style h1, .format-style h2, .format-style h3, .format-style h4, .format-style h5, .format-style h6 {
    line-height: 70px;
    font-weight:bold;
  }

.format-style h1 {
    font-size: 45px;
  }

.format-style h2 {
    font-size: 38px;
  }

.format-style h3 {
    font-size: 32px;
  }

.format-style h4 {
    font-size: 27px;
  }

.format-style h5 {
    font-size: 24px;
  }

.format-style h6 {
    font-size: 21px;
  }

.format-style hr {
    margin:30px 0;
  }

.format-style a {
    text-decoration:underline;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }

.format-style p, .format-style dd {
    line-height:25px;
    margin-bottom:25px;
    font-size:14px;
  }

.format-style dt {
    font-size: 18px;
    line-height: 25px;
    font-weight:bold;
    font-family: 'Open Sans', sans-serif;
  }

.format-style ul, .format-style ol {
    margin-left:20px;
  }

.format-style ul li {
      list-style:disc;
      line-height:20px;
    }

.format-style ol li {
      list-style:decimal;
      line-height:20px;
    }

.format-style fieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 24px;
    padding: 11px 12px;
  }

.format-style label {
    margin-bottom: 15px;
    display: block;
  }

.format-style input[type="radio"],
  .format-style input[type="checkbox"] {
    width:auto;
    height:auto
  }

.format-style input, .format-style textarea, .format-style select {
    margin-top: 5px;
  }

.format-style button, .format-style input.button {
    padding:10px 20px;
    text-transform:uppercase;
    border: 1px solid rgba(255,255,255,0.4);
    cursor:pointer;
    outline:none;
    border: 1px solid rgba(0,0,0,0.4);
    height: auto;
  }

.format-style table td, .format-style table th {
      border:1px solid rgba(0, 0, 0, 0.1);
      padding:10px;
    }

.format-style table td {
      font-size:14px;
    }

.format-style table th {
      text-transform:uppercase;
      font-family: 'Open Sans', sans-serif;
      text-align:left;
    }

.format-style pre{
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 24px;
    max-width: 100%;
    overflow: auto;
    padding: 10px;
    background:#fdfdfd;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

.format-style blockquote{
    position: relative;
    padding: 30px 64px 10px;
    font-size: 34px;
    color: #D7D6D6;
    font-weight: bold;
    font-style: italic;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 4px 4px rgba(197, 197, 197, 0.07)
  }

.format-style blockquote:before{
      content:'\f10d';
      position:absolute;
      top:11px;
      left:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
    }

.format-style blockquote:after{
      content:'\f10e';
      position:absolute;
      bottom:11px;
      right:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
      }

.format-style blockquote * {
      color: #D7D6D6;
      line-height: 40px;
    }

.format-style strong {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }

/* end format-style */

@font-face {
  font-family: 'Industrial';
  src: url('/src/styles/fonts/indust-webfont.eot');
  src: url('/src/styles/fonts/indust-webfont.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/indust-webfont.woff') format('woff'),
    url('/src/styles/fonts/indust-webfont.ttf') format('truetype'),
    url('/src/styles/fonts/indust-webfont.svg#Brush') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Agencybold';
  src: url('/src/styles/fonts/AgencyFB-Bold.eot');
  src: url('/src/styles/fonts/AgencyFB-Bold.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/AgencyFB-Bold.woff') format('woff'),
    url('/src/styles/fonts/AgencyFB-Bold.ttf') format('truetype'),
    url('/src/styles/fonts/AgencyFB-Bold.svg#Brush') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AgencyFB';
  src: url('/src/styles/fonts/AgencyFB-Reg.eot');
  src: url('/src/styles/fonts/AgencyFB-Reg.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/AgencyFB-Reg.woff') format('woff'),
    url('/src/styles/fonts/AgencyFB-Reg.ttf') format('truetype'),
    url('/src/styles/fonts/AgencyFB-Reg.svg#Brush') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Alternate';
  src: url('/src/styles/fonts/altgot2n-webfont.eot');
  src: url('/src/styles/fonts/altgot2n-webfont.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/altgot2n-webfont.woff') format('woff'),
    url('/src/styles/fonts/altgot2n-webfont.ttf') format('truetype'),
    url('/src/styles/fonts/altgot2n-webfont.svg#Brush') format('svg');
  font-weight: normal;
  font-style: normal;
}

.image-gallery-icon{color:#fff;transition:all .3s ease-out;-webkit-appearance:none;appearance:none;background-color:transparent;border:0;cursor:pointer;outline:none;position:absolute;z-index:4;filter:drop-shadow(0 2px 2px #1a1a1a)}

@media(hover: hover)and (pointer: fine){.image-gallery-icon:hover{color:#337ab7}.image-gallery-icon:hover .image-gallery-svg{transform:scale(1.1)}}

.image-gallery-icon:focus{outline:2px solid #337ab7}

.image-gallery-using-mouse .image-gallery-icon:focus{outline:none}

.image-gallery-fullscreen-button,.image-gallery-play-button{bottom:0;padding:20px}

.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{height:28px;width:28px}

@media(max-width: 768px){.image-gallery-fullscreen-button,.image-gallery-play-button{padding:15px}.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{height:24px;width:24px}}

@media(max-width: 480px){.image-gallery-fullscreen-button,.image-gallery-play-button{padding:10px}.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{height:16px;width:16px}}

.image-gallery-fullscreen-button{right:0}

.image-gallery-play-button{left:0}

.image-gallery-left-nav,.image-gallery-right-nav{padding:50px 10px;top:50%;transform:translateY(-50%)}

.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{height:120px;width:60px}

@media(max-width: 768px){.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{height:72px;width:36px}}

@media(max-width: 480px){.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{height:48px;width:24px}}

.image-gallery-left-nav[disabled],.image-gallery-right-nav[disabled]{cursor:disabled;opacity:.6;pointer-events:none}

.image-gallery-left-nav{left:0}

.image-gallery-right-nav{right:0}

.image-gallery{-webkit-user-select:none;-o-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0);position:relative}

.image-gallery.fullscreen-modal{background:#000;bottom:0;height:100%;left:0;position:fixed;right:0;top:0;width:100%;z-index:5}

.image-gallery.fullscreen-modal .image-gallery-content{top:50%;transform:translateY(-50%)}

.image-gallery-content{position:relative;line-height:0;top:0}

.image-gallery-content.fullscreen{background:#000}

.image-gallery-content .image-gallery-slide .image-gallery-image{max-height:calc(100vh - 80px)}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image{max-height:100vh}

.image-gallery-slide-wrapper{position:relative}

.image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right{display:inline-block;width:calc(100% - 110px)}

@media(max-width: 768px){.image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right{width:calc(100% - 87px)}}

.image-gallery-slide-wrapper.image-gallery-rtl{direction:rtl}

.image-gallery-slides{line-height:0;overflow:hidden;position:relative;white-space:nowrap;text-align:center}

.image-gallery-slide{left:0;position:absolute;top:0;width:100%}

.image-gallery-slide.center{position:relative}

.image-gallery-slide .image-gallery-image{width:100%;object-fit:contain}

.image-gallery-slide .image-gallery-description{background:rgba(0,0,0,.4);bottom:70px;color:#fff;left:0;line-height:1;padding:10px 20px;position:absolute;white-space:normal}

@media(max-width: 768px){.image-gallery-slide .image-gallery-description{bottom:45px;font-size:.8em;padding:8px 15px}}

.image-gallery-bullets{bottom:20px;left:0;margin:0 auto;position:absolute;right:0;width:80%;z-index:4}

.image-gallery-bullets .image-gallery-bullets-container{margin:0;padding:0;text-align:center}

.image-gallery-bullets .image-gallery-bullet{-webkit-appearance:none;appearance:none;background-color:transparent;border:1px solid #fff;border-radius:50%;box-shadow:0 2px 2px #1a1a1a;cursor:pointer;display:inline-block;margin:0 5px;outline:none;padding:5px;transition:all .2s ease-out}

@media(max-width: 768px){.image-gallery-bullets .image-gallery-bullet{margin:0 3px;padding:3px}}

@media(max-width: 480px){.image-gallery-bullets .image-gallery-bullet{padding:2.7px}}

.image-gallery-bullets .image-gallery-bullet:focus{transform:scale(1.2);background:#337ab7;border:1px solid #337ab7}

.image-gallery-bullets .image-gallery-bullet.active{transform:scale(1.2);border:1px solid #fff;background:#fff}

@media(hover: hover)and (pointer: fine){.image-gallery-bullets .image-gallery-bullet:hover{background:#337ab7;border:1px solid #337ab7}.image-gallery-bullets .image-gallery-bullet.active:hover{background:#337ab7}}

.image-gallery-thumbnails-wrapper{position:relative}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal{touch-action:pan-y}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical{touch-action:pan-x}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl{direction:rtl}

.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{display:inline-block;vertical-align:top;width:100px}

@media(max-width: 768px){.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{width:81px}}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails{height:100%;width:100%;left:0;padding:0;position:absolute;top:0}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail{display:block;margin-right:0;padding:0}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail{margin-left:0;margin-top:2px}

.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{margin:0 5px}

@media(max-width: 768px){.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{margin:0 3px}}

.image-gallery-thumbnails{overflow:hidden;padding:5px 0}

@media(max-width: 768px){.image-gallery-thumbnails{padding:3px 0}}

.image-gallery-thumbnails .image-gallery-thumbnails-container{cursor:pointer;text-align:center;white-space:nowrap}

.image-gallery-thumbnail{display:inline-block;border:4px solid transparent;transition:border .3s ease-out;width:100px;background:transparent;padding:0}

@media(max-width: 768px){.image-gallery-thumbnail{border:3px solid transparent;width:81px}}

.image-gallery-thumbnail+.image-gallery-thumbnail{margin-left:2px}

.image-gallery-thumbnail .image-gallery-thumbnail-inner{display:block;position:relative}

.image-gallery-thumbnail .image-gallery-thumbnail-image{vertical-align:middle;width:100%;line-height:0}

.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus{outline:none;border:4px solid #337ab7}

@media(max-width: 768px){.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus{border:3px solid #337ab7}}

@media(hover: hover)and (pointer: fine){.image-gallery-thumbnail:hover{outline:none;border:4px solid #337ab7}}

@media(hover: hover)and (pointer: fine)and (max-width: 768px){.image-gallery-thumbnail:hover{border:3px solid #337ab7}}

.image-gallery-thumbnail-label{box-sizing:border-box;color:#fff;font-size:1em;left:0;line-height:1em;padding:5%;position:absolute;top:50%;text-shadow:0 2px 2px #1a1a1a;transform:translateY(-50%);white-space:normal;width:100%}

@media(max-width: 768px){.image-gallery-thumbnail-label{font-size:.8em;line-height:.8em}}

.image-gallery-index{background:rgba(0,0,0,.4);color:#fff;line-height:1;padding:10px 20px;position:absolute;right:0;top:0;z-index:4}

@media(max-width: 768px){.image-gallery-index{font-size:.8em;padding:5px 10px}}
